import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"faq"} />
		<Helmet>
			<title>
				FAQ | Ziklo Smart'ta Sıkça Sorulan Sorular
			</title>
			<meta name={"description"} content={"Ziklo Akıllı FAQ ile Yanıtları Ortaya Çıkarın"} />
			<meta property={"og:title"} content={"FAQ | Ziklo Smart'ta Sıkça Sorulan Sorular"} />
			<meta property={"og:description"} content={"Ziklo Akıllı FAQ ile Yanıtları Ortaya Çıkarın"} />
			<meta property={"og:image"} content={"https://ziklosmart.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://ziklosmart.com/img/6466149.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://ziklosmart.com/img/6466149.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://ziklosmart.com/img/6466149.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://ziklosmart.com/img/6466149.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://ziklosmart.com/img/6466149.png"} />
			<meta name={"msapplication-TileImage"} content={"https://ziklosmart.com/img/6466149.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="140px 0" sm-padding="40px 0 40px 0" background="url(https://ziklosmart.com/img/2.jpg) center/cover">
			<Override slot="SectionContent" sm-align-items="center" />
			<Box max-width="760px" padding="50px 80px 80px 50px" background="--color-light" color="--dark">
				<Text
					as="h4"
					font="--base"
					color="--grey"
					letter-spacing="1px"
					text-transform="uppercase"
					margin="6px 0"
				>
					SSS
				</Text>
				<Text as="h2" font="--headline2" margin="0 0 12px 0">
				Sıkça Sorulan Sorular
				</Text>
				<Text font="--base">
					ДEn acil sorularınızı açıklık ve uzmanlıkla ele aldığımız Ziklo Smart'ın SSS bölümüne hoş geldiniz. İster piyasanın karmaşıklığında gezinen ilk kez bir alıcı olun, ister mülkünüzün değerini en üst düzeye çıkarmak için stratejiler arayan deneyimli bir satıcı olun, SSS'lerimiz her adımda size güç verecek kapsamlı çözümler sunar.
				</Text>
			</Box>
		</Section>
		<Section padding="100px 0 100px 0" background="#ffffff" quarkly-title="FAQ-9">
			<Text margin="0px 0px 70px 0px" font="normal 600 42px/1.2 --fontFamily-sans" color="--darkL1" sm-margin="0px 0px 50px 0px">
				FAQ
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				grid-template-columns="1fr"
				grid-gap="50px 50px"
				lg-grid-template-columns="repeat(2, 1fr)"
				md-grid-template-columns="1fr"
				sm-grid-gap="35px 0"
				flex-direction="column"
			>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="40%"
						md-width="100%"
					>
						Ev satın alma sürecine nasıl başlayabilirim?
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Yolculuğunuza deneyimli temsilcilerden oluşan ekibimizle iletişime geçerek başlayın. Tercihlerinizi anlamak ve ilk aramadan kapanışa kadar tüm süreç boyunca size rehberlik etmek için sizinle yakın bir şekilde çalışacağız.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="40%"
						md-width="100%"
					>
						Mülkümü satarken nelere dikkat etmeliyim?
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Bir mülkü satmak dikkatli bir planlama ve hazırlık gerektirir. Ekibimiz piyasa eğilimlerini değerlendirmenize, doğru fiyatı belirlemenize ve potansiyel alıcıları çekmek için evinizi sahnelemenize yardımcı olacaktır.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="40%"
						md-width="100%"
					>
						Mülk yönetimi hizmetleri sunuyor musunuz?
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Evet, yatırımınızın değerini en üst düzeye çıkarmanıza yardımcı olmak için kapsamlı mülk yönetimi hizmetleri sunuyoruz. Kiracı taramasından bakıma kadar mülk yönetiminin tüm yönlerini profesyonellik ve özenle ele alıyoruz.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="40%"
						md-width="100%"
					>
						Mülkümü Ziklo Smart aracılığıyla kiralayabilir miyim?
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Kesinlikle! Evlerini veya yatırım amaçlı mülklerini kiraya vermek isteyen mülk sahipleri için kiralama yönetimi hizmetleri sunuyoruz. Nitelikli kiracıları bulma ve kira sözleşmelerini sizin adınıza yönetme işini bize bırakın.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="40%"
						md-width="100%"
					>
						Ziklo Smart'ı diğer emlak acentelerinden ayıran nedir?
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Ziklo Smart'ta kişiselleştirilmiş hizmete, dürüstlüğe ve şeffaflığa öncelik veriyoruz. Kendini işine adamış ekibimiz, her müşterinin emlak yolculuğu boyunca hak ettiği ilgi ve desteği almasını sağlamak için yukarıda ve öteye gider.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="40%"
						md-width="100%"
					>
						İlk kez ev alacaklar için danışmanlık hizmeti sunuyor musunuz?
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Evet, emlak piyasasında yeni olan bireyler için özel danışmanlık hizmetleri sunuyoruz. Uzmanlarımız tüm süreç boyunca size rehberlik edecek, değerli bilgiler sunacak ve bilinçli kararlar almanıza yardımcı olacaktır.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});